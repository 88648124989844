import React from 'react';
import RöverRoomJPG from '@assets/images/röver-office.jpg';
import RöverRoomMobileJPG from '@assets/images/röver-office-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import PsychiatryMain from '~/components/organisms/PsychiatryMain/PsychiatryMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const PsychiatryPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={RöverRoomJPG}
        mobileHero={RöverRoomMobileJPG}
        altHero="Psychiatrie Praxisraum"
        enLink="/en/psychiatry/"
        deLink="/psychiatrie/"
      >
        <PsychiatryMain />
      </HeaderFooterLayout>
    </>
  );
};

export default PsychiatryPage;
