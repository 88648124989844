/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import RöverJPG from '@assets/images/röver.jpg';
import PsychoLeafJPG from '@assets/images/psycho-leaf.jpeg';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainerNull,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/psychiatrie';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/psychiatry';

const PsychiatryMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('PsychoContent1') || tEn('PsychoContent1'),
            }}
          />
          <FlexContainerNull>
            <ImageContainer>
              <Image
                src={RöverJPG}
                alt="Psychiaterin Frau Dr. Julia Röver"
                width={350}
              />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('PsychoContent2') || tEn('PsychoContent2'),
                }}
              />
            </List>
          </FlexContainerNull>
          <FlexContainerNull>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('PsychoContent3') || tEn('PsychoContent3'),
                }}
              />
            </List>
            <ImageContainer>
              <Image
                src={PsychoLeafJPG}
                alt="Leaf symbolizing life."
                width={450}
              />
            </ImageContainer>
          </FlexContainerNull>
        </ContainerSmall>
      </Container>
    </>
  );
};

export default PsychiatryMain;
